<template>
    <v-row no-gutters justify="center" style="height: 100%" align="center">
        <v-col style="text-align: center" cols="12">
            <v-card elevation="2" class="my-6">
                <v-app-bar :color="primaryColor" dark flat dense>
                    <v-app-bar-title>Sign in to continue</v-app-bar-title>
                </v-app-bar>
                <v-card-text>
                    <v-btn :to="loginRoute" elevation="2" :style="primaryButtonStyle" class="my-6" outlined>Sign in</v-btn>
                    <p>No account yet? <a :href="mainWebsiteURL">Sign up</a></p>
                </v-card-text>
            </v-card>
            <p class="mt-8"><a :href="mainWebsiteURL">Learn more about Unicorn Springs</a></p>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: ['nextRoute'], // caller can pass `this.$router.currentRoute.fullPath` as the value of this prop to return to same page after login
    computed: {
        ...mapGetters({
            mainWebsiteURL: 'mainWebsiteURL',
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
        }),
        loginRoute() {
            if (this.nextRoute) {
                return this.$nav.route({ name: 'brand-login', query: { next: this.nextRoute } });
            }
            return this.$nav.route({ name: 'brand-login' });
        },
    },
};
</script>
