<template>
    <v-footer fixed dark padless app class="no-print">
        <v-row justify="center" no-gutters>
            <v-col cols="12" class="text-caption pa-0 dark font-weight-light" style="text-align: center">
                    Powered by <a :href="mainWebsiteURL" target="_blank" class="white--text"><strong>Unicorn Springs</strong></a>
            </v-col>
        </v-row>
    </v-footer>
</template>
<style>
@media print {
    .no-print {
        display: none !important;
    }
}
</style>
<script>
import { mapGetters } from 'vuex';

export default {
    data: () => ({
        alertDialog: false,
    }),
    computed: {
        ...mapGetters({
            mainWebsiteURL: 'mainWebsiteURL',
        }),
    },
};
</script>
