<template>
    <nav class="no-print">
        <v-app-bar fixed fade-on-scroll elevation="1" dense color="white" app clipped-left>
            <v-toolbar-title style="font-size: 16px!important; text-align: left !important;">
                <router-link :to="{ name: 'front' }" style="text-decoration: none;" v-if="brandselector === 'hostname'">
                    <v-img v-if="!brandprofile" alt="Unicorn Springs" src="@/assets/logo/unicornsprings/UnicornSprings_light_264x48.png" class="mr-1 float-left" width="196" height="36" fit="inside"></v-img>
                    <BrandImage v-if="brandprofile" :brandprofile="brandprofile" intent="logotype" mode="light" :height="36" fit="inside" contain position="left"/>
                </router-link>
                <router-link :to="{ name: 'brand-front', params: { brandprofile } }" style="text-decoration: none;" v-if="brandselector === 'brandprofile'">
                    <BrandImage v-if="brandprofile" :brandprofile="brandprofile" intent="logotype" mode="light" :height="36" fit="inside" contain position="left"/>
                </router-link>
            </v-toolbar-title>
            <v-progress-linear
                :active="isLoading"
                :indeterminate="isLoading"
                absolute
                bottom
                :color="primaryColor"
            ></v-progress-linear>
            <v-spacer></v-spacer>

            <TextButton class="text-headline mx-2" style="font-size: 1em; line-height: 1.1em;" underline @click="navigateToShopItems" v-if="isDisplayShopLinkEnabled">
                Shop
            </TextButton>
            <TextButton class="text-headline mx-2" style="font-size: 1em; line-height: 1.1em;" underline @click="navigateToServicesItems" v-if="isDisplayServicesLinkEnabled">
                Services
            </TextButton>
            <TextButton class="text-headline mx-2" style="font-size: 1em; line-height: 1.1em;" underline @click="navigateToDonationItems" v-if="isDisplayDonateLinkEnabled">
                Donate
            </TextButton>
            <div class="ml-2 mr-1" style="border-right: 1px solid black;">&nbsp;</div>
            <template v-if="isAuthenticated && account && !brandNotFoundError">
                <font-awesome-icon :icon="['fas', 'folder-open']" :color="primaryColor" fixed-width class="ml-4"/>
                <TextButton class="text-headline ml-2" style="font-size: 1.1em; line-height: 1.1em;" @click="navigateToAccountDashboard">
                    <span v-html="accountNameNbsp"></span>
                </TextButton>
            </template>
            <v-btn icon :color="primaryColor" @click="search" v-if="!brandNotFoundError">
                <font-awesome-icon :icon="['fas', 'search']" fixed-width></font-awesome-icon>
            </v-btn>
            <v-menu v-model="helpMenu" offset-y open-on-click open-on-hover close-delay="100">
                <template #activator="{ on, attrs }">
                    <v-btn icon :color="primaryColor" v-bind="attrs" v-on="on">
                        <!-- TODO: when we upgrade to fontawesome 6.x this should be changed to 'message-question', or at least check how it looks that way -->
                        <font-awesome-icon :icon="['fas','question']" fixed-width/>
                    </v-btn>
                </template>
                <v-card>
                    <v-card-text>
                        Questions or comments?
                        <a href="https://liberty.io/contact/" target="_blank">Contact us!</a>
                    </v-card-text>
                </v-card>
            </v-menu>
            <v-btn icon :color="primaryColor" @click="navigateToCart">
                <!-- TODO: if cart has items in it, the icon should be different -->
                <!-- TODO: hover over this icon should show a tooltip with how many items are in the cart, or maybe we show that as a little badge number on the icon -->
                <font-awesome-icon :icon="['fad', 'shopping-cart']" fixed-width></font-awesome-icon>
            </v-btn>
            <!-- <div v-if="!isAuthenticated">
                <div v-show="$vuetify.breakpoint.smAndUp">
                    <v-tabs :slider-color="primaryColor" right>
                        <v-tab :to="{ name: 'front' }" v-show="false"></v-tab>
                        <v-tab to="/about">About</v-tab>
                        <v-tab to="/login">Login</v-tab>
                    </v-tabs>
                </div>
                <div v-show="$vuetify.breakpoint.xsOnly">
                    <v-menu offset-y open-on-click open-on-hover close-delay="100">
                        <template #activator="{ on, attrs }">
                            <v-btn text v-bind="attrs" v-on="on">
                                <font-awesome-icon right :style="primaryIconStyle" :icon="['fas', 'bars']" fixed-width/>
                            </v-btn>
                        </template>
                        <v-tabs vertical :slider-color="primaryColor">
                            <v-tab :to="{ name: 'front' }" v-show="false"></v-tab>
                            <v-tab to="/about">About</v-tab>
                            <v-tab to="/login">Login</v-tab>
                        </v-tabs >
                    </v-menu>
                </div>
            </div> -->
            <v-btn outlined :color="primaryColor" v-if="!isAuthenticated && !brandNotFoundError && this.$route.name !== 'login' && this.$route.name !== 'brand-login'" @click="login">
                <!-- TODO: when we upgrade to fontawesome 6.x this should be changed to 'message-question', or at least check how it looks that way -->
                <!-- <font-awesome-icon :icon="['fas','question']" fixed-width/> -->
                Sign in
            </v-btn>
            <v-menu offset-y open-on-click open-on-hover close-delay="100" v-if="isAuthenticated && !brandNotFoundError">
                <template #activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" :color="primaryColor">
                        <Avatar :attr="mainProfile" :size="28"/>
                    </v-btn>
                </template>
                <v-list class="ma-0 pa-0">
                    <v-list-item v-if="user">
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ user.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ user.email }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <!-- <v-list-item :to="{ name: 'dashboard' }">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'chart-line']" :color="primaryColor" fixed-width/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Dashboard
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item> -->
                    <v-list-item :to="this.$nav.route({ name: 'profile' })">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'user']" :color="primaryColor" fixed-width/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Profile
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="this.$nav.route({ name: 'user-account-list' })">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'folder']" :color="primaryColor" fixed-width/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Accounts
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'service-admin' }" v-if="isPermitServiceAdmin">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'id-badge']" :color="primaryColor" fixed-width/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Service Admin
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider/>
                    <v-list-item @click="logout">
                        <v-list-item-icon>
                            <font-awesome-icon :icon="['fas', 'shield-alt']" :color="primaryColor" fixed-width/>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                Logout
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <!-- <div>
                        <v-tabs vertical :slider-color="primaryColor" class="menutabs">
                            <v-tab :to="{ name: 'dashboard' }" v-show="false"></v-tab>
                            <v-tab :to="{ name: 'profile' }">Profile</v-tab>
                            <v-tab :to="{ name: 'user-organization-list' }">Organizations</v-tab>
                            <v-tab :to="{ name: 'dashboard' }">Dashboard</v-tab>
                            <v-tab :to="{ name: 'service-admin' }" v-if="isPermitServiceAdmin">Service admin</v-tab>
                            <v-divider></v-divider>
                            <v-tab @click="logout">Logout</v-tab>
                        </v-tabs>
                    </div> -->
                </v-list>
            </v-menu>
        </v-app-bar>
    </nav>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Avatar from '@/components/Avatar.vue';
import BrandImage from '@/components/BrandImage.vue';
import TextButton from '@/components/TextButton.vue';

export default {
    components: {
        Avatar,
        BrandImage,
        TextButton,
    },

    data: () => ({
        helpMenu: false,
    }),

    computed: {
        ...mapState({
            isAuthenticated: (state) => state.session.isAuthenticated,
            organization: (state) => state.organization,
            user: (state) => state.user,
            account: (state) => state.account,
            brandselector: (state) => state.brandselector,
            brandprofile: (state) => state.brandprofile,
            brandNotFoundError: (state) => state.brandNotFoundError,
        }),
        ...mapGetters({
            isLoading: 'isLoading',
            primaryColor: 'primaryColor',
            primaryTextColor: 'primaryTextColor',
            primaryIconStyle: 'primaryIconStyle',
        }),
        mainProfile() {
            return { name: this.user?.name, email: this.user?.email };
        },
        isPermitServiceAdmin() {
            return this.isAuthenticated && Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        accountNameNbsp() {
            // the intent is to replace spaces with &nbsp; but because we have to use v-html to render this, we ALSO need to escape angle brackets and quotes to prevent XSS attacks with the account name itself (because user can edit the account name, and then admins can look at it, so the attack would be an authorized user trying to take over an admin's session)
            return this.account?.name
                .replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/"/g, '&quot;')
                .replace(/'/, '&apos;')
                .replace(/ /g, '&nbsp;');
        },
        isDisplayShopLinkEnabled() {
            return true; // TODO: only if the enterprise has clothing ,merchandise, etc. to sell or enterprise admin checked the box in storefront settings; organization.???
        },
        isDisplayDonateLinkEnabled() {
            return true; // TODO: only if the enterprise has donation items or enterprise admin checked the box in storefront settings; organization.???
        },
        isDisplayServicesLinkEnabled() {
            return true; // TODO: only if the enterprise has saas items
        },
    },

    methods: {
        login() {
            const { fullPath } = this.$router.currentRoute;
            if (this.brandselector === 'brandprofile') {
                this.$nav.push({ name: 'brand-login', params: { brandprofile: this.brandprofile }, query: { next: fullPath } });
            } else {
                this.$nav.push({ name: 'login', query: { next: fullPath } });
            }
        },
        async logout() {
            console.log('logout: redirecting to front page');
            await this.$store.dispatch('logout');
            if (this.$route.name !== 'front') {
                if (this.brandprofile) {
                    this.$nav.push({ name: 'brand-front' });
                } else {
                    this.$nav.push({ name: 'front' });
                }
            }
        },
        search() {
            if (this.$route.name === 'search' || this.$route.name === 'brand-catalog-search') {
                // reset the search input to empty string
                this.$bus.$emit('search', '');
            } else {
                // this.$nav.push({ name: 'search' /* , query: { q: this.searchQuery } */ });
                this.$nav.push({ name: 'brand-catalog-search', query: { accountId: this.account?.id, q: '', t: Date.now() } });
            }
        },
        navigateToAccountDashboard() {
            this.$nav.push({ name: 'account-dashboard', params: { accountId: this.account?.id }, query: { t: Date.now() } });
        },
        navigateToCart() {
            this.$nav.push({ name: 'brand-cart', query: { accountId: this.account?.id, t: Date.now() } });
        },
        navigateToShopItems() {
            this.$nav.push({ name: 'brand-catalog-search', query: { type: 'clothing,merchandise', accountId: this.account?.id, t: Date.now() } });
        },
        navigateToDonationItems() {
            this.$nav.push({ name: 'brand-catalog-search', query: { type: 'donation', accountId: this.account?.id, t: Date.now() } });
        },
        navigateToServicesItems() {
            this.$nav.push({ name: 'brand-catalog-search', query: { type: 'service', accountId: this.account?.id, t: Date.now() } });
        },
    },
};
</script>

<style>
    @media print {
        .no-print {
            display: none !important;
        }
    }
    @media screen {
        div.menutabs div[role="tablist"] { width: 100%; }
        div.menutabs [role="tab"] { justify-content: flex-start; padding-left: 48px;}
    }
</style>
