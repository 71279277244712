<template>
    <v-app>
        <!-- <Navbar/> -->
        <v-main style="background-color: #efefef; height: 100%;" class="ma-0 print">
            <v-container class="ma-0 pa-0" fluid style="height: 100%;">
                <v-row no-gutters class="ma-0 pa-0" justify="center" style="height: 100%;">
                    <v-col cols="12" class="ma-0 pa-0" style="height: 100%;">
                        <AlertBarNonProduction v-if="!isProduction"></AlertBarNonProduction>
                        <slot/>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
        <Footer/>
    </v-app>
</template>

<script>
import { mapState } from 'vuex';
// import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';
import AlertBarNonProduction from '@/components/AlertBarNonProduction.vue';

export default {
    name: 'SearchLayout',
    components: {
        // Navbar,
        Footer,
        AlertBarNonProduction,
    },
    computed: {
        ...mapState({
            organization: (state) => state.organization,
        }),
        isProduction() {
            return this.organization?.is_production ?? true;
        },
    },
};
</script>

<style lang="css">
  /* * {
    font-family: 'NunitoSans', sans-serif
  } */
@media print {
  /* removes padding corresponding to navbar and footer when printing document */
  .print {
    padding: 0 !important;
  }
}
</style>
