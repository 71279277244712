<template>
  <v-app>
    <Navbar/>
    <v-main style="background-color: #efefef; height: 100%;" class="ma-0 print">
      <v-container class="ma-0 pa-0" fluid style="height: 100%;">
        <v-row no-gutters class="ma-0 pa-0" justify="center" style="height: 100%;">
        <v-col cols="12" class="ma-0 pa-0" style="height: 100%;">
          <AlertBarNonProduction v-if="!isProduction"></AlertBarNonProduction>
          <slot/>
        </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';
import AlertBarNonProduction from '@/components/AlertBarNonProduction.vue';

export default {
    name: 'BrandLayout',
    components: {
        Navbar,
        Footer,
        AlertBarNonProduction,
    },
    computed: {
        ...mapState({
            organization: (state) => state.organization,
        }),
        isProduction() {
            return this.organization?.is_production ?? true;
        },
    },
    watch: {
        // $route(newValue, oldValue) {
        //     console.log(`app.vue route watcher; new ${JSON.stringify(newValue.params.brandprofile)} old ${JSON.stringify(oldValue.params.brandprofile)}`);
        //     if (newValue.params.brandprofile !== oldValue.params.brandprofile) {
        //         this.$store.commit('brandprofile', this.$route.params.brandprofile);
        //         this.selectBrand();
        //     }
        // },
        // brandNotFoundError(newValue) {
        //     if (newValue && this.$route.name !== 'search' && this.hostname === window.location.hostname) {
        //         this.$nav.push({ name: 'search' });
        //     }
        // },
    },

    methods: {
        // selectBrand() {
        //     if (this.$route.params.brandprofile) {
        //         this.$store.commit('brandprofile', this.$route.params.brandprofile);
        //         this.$store.commit('brandselector', 'brandprofile');
        //     } else {
        //         this.$store.commit('brandprofile', null);
        //         this.$store.commit('brandselector', 'hostname');
        //     }
        //     this.$store.dispatch('loadBrand');
        //     this.$store.dispatch('loadPalette', { mode: 'light' });
        // },
    },

    created() {
        // console.log(`app.vue: created, initializing with route ${JSON.stringify(this.$route.fullPath)}`);
        // this.$store.commit('hostname', window.location.hostname);
        // if (this.$route.params.brandprofile) {
        //     this.$store.commit('brandprofile', this.$route.params.brandprofile);
        //     this.$store.commit('brandselector', 'brandprofile');
        // } else {
        //     this.$store.commit('brandprofile', null);
        //     this.$store.commit('brandselector', 'hostname');
        // }
        // this.$store.dispatch('loadBrand');
        // this.$store.dispatch('loadPalette', { mode: 'light' });
        // this.$store.dispatch('loadSession');
    },
};
</script>

<style lang="css">
  /* * {
    font-family: 'NunitoSans', sans-serif
  } */
@media print {
  /* removes padding corresponding to navbar and footer when printing document */
  .print {
    padding: 0 !important;
  }
}
</style>
